/* colours */
$black: black;
$white: white;
$blue: blue;
$green: green;

$alert: #e00000;
$alert_soft: #ab42d1;
$alt: #253e68;
$alt_light: #929fe3;
$amber: #dd9900;

$contrast_alert_opaque: #d8222280;
$contrast_color: #4b61d1;
$contrast_extreme: #0321bd;
$contrast_semi_opaque: rgba(3, 10, 100, 0.2);
$contrast_soft: $alt_light;
$contrast_softer: #b5bee9;
$contrast_softest: #cdd3f1;
$contrast_hard: #402222;

$extreme_hard: $black;
$extreme_soft: $white;

$hub: #36454f;

$inert: #bbbbbb;

$invalid_hard: #ff4141;
$invalid_soft: #ffcaca;
$invalid_softest: #fff0f0;

$main_background: #f5f5f5;
$main_font_color: #1c1c1c;
$main_link_color: $contrast_extreme;

$middle_hardest: #333;
$middle_harder: #707070;
$middle_hard: #ccc;
$middle_regular: #ddd;
$middle_soft: #eee;
$middle_softer: #f0f0f0;
$middle_softest: #fbfbfb;

$notice_background: rgba(20, 100, 20, 0.6);

$opaque_heavy: rgba(255, 255, 255, 0.8);
$opaque_regular: rgba(255, 255, 255, 0.5);
$opaque_faint: rgba(255, 255, 255, 0.2);

$shading_heavy: rgba(0, 0, 0, 0.8);
$shading_medium: rgba(0, 0, 0, 0.2);
$shading_faint: rgba(0, 0, 0, 0.08);

$side_background: $contrast_extreme;
$side_icon_color: $white;
$side_font_color: $white;

$success: #008000;
