@import "theme";

/*** PLEASE MAINTAIN ALPHABETICAL ORDER ****/

.accept,
.reject {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border-radius: 6px;
  box-shadow: 1px 1px 2px $middle_regular;
  cursor: pointer;
}
.accept {
  background: $success;
  color: $extreme_soft;
}
.reject {
  background: $middle_softest;
  color: $middle_harder;
}
.reject:hover {
  background: $extreme_soft;
  color: $alert;
}
.accept:hover {
  color: $extreme_soft;
  opacity: 0.91;
}

.alert {
  color: $alert;
}

.amber {
  color: $amber;
}

.auto-margin-narrow {
  margin: auto;
}

.avatar {
  position: relative;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fbfbfb;
}

.avatar.large {
  width: 120px;
  height: 120px;
}

.avatar.medium {
  width: 42px;
  height: 42px;
}

.avatar.med {
  width: 36px;
  height: 36px;
}

.avatar.small {
  width: 24px;
  height: 24px;
}

.background-contrast {
  background-color: $contrast_color;
  color: $white;
}

.batch-loading {
  font-size: 2em;
  color: $middle_hard;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  user-select: none;
  margin: auto;
  display: inline-block;
}

.button,
.button-cancel,
.button-warning,
.button-secondary {
  font-size: 0.96em;
  padding: 0.5em 2em;
  border-radius: 0.5rem;
  font-weight: 500;
  text-align: center;
  align-self: center;
  cursor: pointer;
}
.button:focus,
.button-cancel:focus {
  outline-offset: -1px;
  outline-style: solid;
}
.button,
.button-glyph {
  background-color: $contrast_color;
  border: 2px solid $contrast_color;
  color: $extreme_soft;
  outline-color: $contrast_extreme;
}
.button.pub {
  background-color: $main_background;
  color: $contrast_extreme;
}
.button-cancel {
  background-color: $middle_soft;
  border: 1px solid $middle_soft;
  outline-color: $middle_regular;
  color: $middle_harder;
}
.button-secondary {
  border: 2px solid $contrast_color;
  outline-color: $middle_regular;
  color: $contrast_color;
  outline-color: $contrast_extreme;
}
.button-warning {
  border: 2px solid $alert;
  outline-color: $alert;
  color: $alert;
  outline-color: $alert;
}
.button:hover,
.button-secondary:hover,
.button-glyph:hover {
  border-color: $contrast_extreme;
}
.button-cancel:hover {
  border-color: $middle_regular;
}
.button-warning:hover {
  border-color: $invalid_hard;
}
.button-edit {
  display: flex;
  border: none;
  border-radius: 6px;
  line-height: 1.5rem;
  height: 1.5rem;
  align-items: center;
  font-size: 0.8em;
  box-shadow: 1px 1px 2px $middle_regular;
  background-color: #fbfbfb;
  cursor: pointer;
}
.button-edit:hover {
  background: $middle_softer;
}
.button-edit:target {
  text-decoration: underline;
  background-color: $alert;
}
.button-on,
.button-off {
  padding: 0.45em 1em;
  cursor: pointer;
  align-self: center;
  text-align: center;
  border-radius: 6px;
  font-weight: 500;
  outline-width: 1px;
}
.button-off {
  background-color: $middle_soft;
  outline-color: $middle_regular;
  color: $middle_harder;
}
.button-off:hover {
  background-color: $middle_regular;
  color: $middle_hardest;
}
.button-on {
  background-color: $contrast_softer;
  outline-color: $contrast_soft;
  color: $extreme_hard;
}
.button-on:hover {
  background-color: $contrast_soft;
}

.button-glyph {
  font-family: "Glyphs";
  font-size: 0.8em;
  line-height: 1.4em;
  border-radius: 0.25rem;
  font-weight: 500;
  text-align: center;
  width: 1.4rem;
  height: 1.4rem;
}
.button-glyph.large {
  font-size: 1em;
  line-height: 2em;
  width: 2rem;
  height: 2rem;
}
.button-withdraw {
  border: 1px solid transparent;
  outline-color: $middle_regular;
  color: $contrast_color;
  outline-color: $contrast_extreme;
  border-radius: 4px;
  padding: 0.25em 1em;
  box-shadow: 1px 1px 2px $middle_regular;
  background-color: $opaque_regular;
}
.button-withdraw:hover {
  border-color: $contrast_color;
  color: $alert;
  background-color: $white;
}
.button.narrow,
.button-secondary.narrow {
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.button.alert {
  background-color: $alert;
  border-color: $alert;
  outline-color: $alert;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.block-info {
  display: flex;
  flex-direction: column;
  padding: 6px 0 0 0;
  box-sizing: content-box;
}
.block-title {
  font-weight: 600;
  font-size: 1.1em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.block-step {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 400ms;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.card-block,
.card-block-success {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  box-sizing: content-box;
  transition: 250ms;
  padding: 16px 0;
  border-radius: 6px;
}
.card-block:hover {
  cursor: pointer;
  background-color: $contrast_softest;
}
.card-block-summary {
  display: inline-block;
}
.card-free {
  width: 100%;
  background-color: $white;
  box-shadow: 1px 1px 2px $middle_regular;
  padding: 0.5em 1em;
}
.card-long {
  width: 100%;
  height: auto;
  background-color: $white;
  margin: 0.75em 0;
  padding: 1em 0.5em;
  box-shadow: 1px 1px 2px $middle_regular;
}
.card-long-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.card-username {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-username.long {
  max-width: 240px;
}

.center {
  text-align: center;
  justify-content: center;
}

.clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.clamped.one {
  -webkit-line-clamp: 1;
}
.clamped.two {
  -webkit-line-clamp: 2;
}
.clamped.three {
  -webkit-line-clamp: 3;
}
.clamped.four {
  -webkit-line-clamp: 4;
}

.combo-wrap {
  height: 1.5em;
  // line-height: 1em;
  padding: 0.25em 0.5em;
  margin: 0.25em 0;
  overflow: hidden;
  cursor: pointer;
}
.combo-wrap:focus {
  background: $middle_hard;
  text-decoration: none;
}

.comment {
  font-size: 0.75em;
  padding: 0.75em 0.5em;
  border-bottom: 1px solid $middle_regular;
}

.contrast {
  color: $contrast_color;
}

.crest-box {
  border: 1px solid $middle_regular;
  width: 122px;
  height: 122px;
}
.crest-edit-container {
  height: 186px;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
  background-color: $middle_soft;
  padding: 10px 2px;
  border: 2px dashed $middle_hardest;
}
.crest-edit-container.invalid {
  background-color: $invalid_soft;
  border: 2px dashed $invalid_hard;
}
.crest-ranger {
  width: 100%;
}
.crest-settings {
  position: absolute;
  top: 0;
  width: calc(100% - 130px);
  padding: 12px 6px;
  flex-direction: column;
  flex: 1;
}
.crest-small {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  box-sizing: content-box;
  border-radius: 6px;
}

.delete-button {
  font-size: 10px;
  font-family: "Glyphs";
  font-weight: bold;
  border: 2px solid $alert;
  padding: 2px;
  border-radius: 50%;
  color: $alert;
  background-color: transparent;
  width: 20px;
  height: 20px;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
}
.delete-button:hover {
  color: $white;
  background-color: $alert;
}

.dotdotdot:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…";
  /* ascii code for the ellipsis character */
  width: 0px;
}

.document {
  width: 100%;
  min-height: 400px;
  max-width: 1100px;
  border: 1px solid $middle_soft;
  box-shadow: 1px 1px 2px $middle_regular;
}

.drag-and-drop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.drag-and-drop-container {
  width: 100%;
  height: 200px;
  border: 2px dashed $middle_hardest;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: $middle_soft;
}

.email {
  padding: 0.75rem;
  font-size: 0.9rem;
}
.email:not(:last-child) {
  border-bottom: 1px solid $middle_regular !important;
}
.email-container {
  border: 1px solid $middle_regular;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.errtext,
.subtext,
.successtext {
  font-size: 0.9rem;
  padding: 0.2em 0.25em 0 0.25em;
  white-space: pre-wrap;
}
.errtext {
  font-weight: 500;
  color: $alert;
}
.successtext {
  font-weight: 500;
  color: $success;
}

.flashing {
  -webkit-animation-name: blink;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1.7s;
}

.file-container {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  margin: 0 0.25em;
  transition: 0.3s;
}
.file-container > .close {
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: calc(50% - 10px);
  font-size: 20px;
  color: $middle_regular;
  z-index: -1;
}
.file-container:hover > .close {
  z-index: 5;
}
.close.glyphs:focus {
  z-index: 5;
}
.file-container:hover > .message-uploaded-image {
  filter: brightness(50%);
  background-color: $shading_medium;
}

.filter > button {
  padding: 0.25em 1em;
  border-radius: 1em;
}
.filter > button.on {
  color: $white;
  background-color: $contrast_color;
}
.filter > button.off {
  color: $middle_harder;
  background-color: $middle_regular;
}

.flex-auto-fit,
.flex-auto-fit-slim,
.flex-auto-fit-wide {
  flex: 1 1 200px;
  min-width: 200px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.flex-auto-fit {
  flex: 1 1 240px;
  min-width: 240px;
}
.flex-auto-fit-slim {
  flex: 1 1 160px;
  min-width: 160px;
}
.flex-auto-fit-wide {
  flex: 1 1 320px;
  min-width: 320px;
}

.font-alt {
  color: $alt !important;
}
.font-contrast {
  color: $contrast_color;
}
.font-contrast-soft {
  color: $contrast_soft;
}
.font-contrast-hard {
  color: $contrast_hard;
}
.font-easy {
  font-weight: 300;
}
.font-logo {
  font-family: "Logo";
}
.font-soft {
  color: $middle_harder;
}
.font-white {
  color: $white;
}
.font-invert {
  color: $main_background;
}
.font-yongo {
  color: $contrast_extreme;
}

.glyphs {
  font-family: "Glyphs";
}

.grace-notice {
  color: $white;
}
.grace-notice.hard {
  background: $alert;
}
.grace-notice.soft {
  background: $alert_soft;
}

.handle {
  font-size: 0.8em;
  color: $contrast_color;
}
.handle2 {
  font-size: 0.9em;
  font-style: italic;
  color: $middle_harder;
}

.heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  padding: 0.25em;
  margin: 0;
}

.heading:focus {
  border: none;
}

.hover {
  cursor: pointer;
}
.hover-link:hover {
  color: $contrast_color;
  cursor: pointer;
}

.hovering {
  background-color: $middle_soft !important;
}

div.img-view {
  margin: auto 0;
  font-size: 2em;
  padding: 2px;
  color: $extreme_soft;
}
img.img-view {
  max-width: 100%;
  max-height: calc(100vh - 4.2em);
}
.img-view > button {
  color: $extreme_soft;
}
button.img-view {
  color: $extreme_soft;
}

.inert {
  color: $inert;
}

.join-container {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
}

.line {
  border-bottom: 1px solid $middle_regular;
  padding-bottom: 0.25em;
  width: 100%;
  height: 1px;
}

.link,
.link-button,
.link-delete {
  color: $main_link_color;
}
.link-blue {
  color: blue;
}
.link:hover,
.link-blue:hover {
  text-decoration: underline;
  cursor: pointer;
}
.link-delete:hover {
  color: red;
}

.list-container {
  display: flex;
}
.list-container ul {
  list-style: none;
  position: relative;
}
.list-container li {
  margin: 0.5em;
}
.list-container li::before {
  content: "■";
  color: $contrast_color;
  font-size: 0.9em;
  position: relative;
  top: -0.15em;
}

.list-checked {
  text-align: center;
}
.list-checked ul {
  list-style: none;
  display: inline-block;
}
.list-checked li {
  margin: 0.5em 0;
}
.list-checked li::before {
  font-family: "Glyphs";
  color: $success;
  content: "*";
  margin: 0 0.5em;
}

.live-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  padding: 0.4em;
  color: $white;
}
.live-button.off {
  background-color: $opaque_faint;
}
.live-button.on {
  background-color: $contrast_color;
}
.live-button.stop {
  background-color: $alert;
}

.live-container {
  width: 100%;
  max-width: 1400px;
}
.live-stage {
  position: relative;
}
.live-panel {
  margin-top: 1em;
  position: relative;
  height: 300px;
  background-color: white;
}
.live-panel-box {
  width: 100%;
}
.live-control {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 46px;
  justify-content: center;
}
.live-heading {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
}
.live-heading > .muted {
  display: flex;
  gap: 0.25em;
  align-items: center;
  color: $alert;
  background-color: $shading_faint;
  margin: 0.5em;
  padding: 0.5em 1.5em;
  border-radius: 6px;
}
.live-heading > .timer {
  width: 6em;
  text-align: center;
  color: $white;
  background-color: $contrast_alert_opaque;
  margin-top: 0.5em;
  padding: 0.5em 0;
  border-radius: 6px;
}
.live-video {
  position: relative;
  background-color: black;
}

.login {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 3em 0.5em;
  // max-height: calc(100vh - 70px);
}
.login-form {
  width: 100%;
  align-self: center;
  display: grid;
  position: relative;
  overflow: hidden;
  padding: 0 4px;
}
.login-heading {
  font-size: 1.8rem;
}
.login-input {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.logo {
  width: 100%;
  height: auto;
}
.logo-container {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  justify-content: center;
  font-size: 0.6rem;
  color: $white;
  width: 100%;
  border-radius: 6px;
}
.logo-container > span {
  display: flex;
  font-family: "Glyphs";
  overflow: hidden;
  font-size: 4.5em;
  flex: 0 0 0.7em;
  justify-content: center;
}
.logo-container > div {
  font-family: "Logo";
  font-weight: 600;
  white-space: nowrap;
  font-size: 3em;
  line-height: 1.2em;
  padding: 0.1em;
}

.logo-text {
  font-family: "Logo";
  font-size: 1.3em;
  margin: 1em 0.5em 0;
}

.main {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.main-page {
  height: 100%;
}

.menu-crest {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 2.5em;
  width: 2.5em;
  border-radius: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  opacity: 0.85;
}
.menu-icon,
.menu-icon-app,
.heading-icon,
.subheading-icon,
.post-icon {
  font-family: "Glyphs";
  flex-basis: 30px;
  flex-shrink: 0;
  align-self: center;
}
.heading-icon {
  color: $contrast_color;
  font-size: 1.7em;
}
.subheading-icon {
  color: $contrast_color;
  font-size: 1.4em;
}
.menu-icon {
  color: $side_icon_color;
  font-size: 1.5em;
  opacity: 0.8;
}
.menu-icon-app {
  color: $side_icon_color;
  font-size: 2em;
  opacity: 0.8;
}
.menu-item {
  padding: 0.8rem 0.5rem;
  display: flex;
}
.menu-item:hover,
.menu-item.active {
  cursor: pointer;
  background-color: $contrast_semi_opaque;
}
.menu-item:hover .menu-label,
.menu-item:hover .menu-icon,
.menu-item:hover .menu-icon-app,
.menu-item:hover .menu-crest,
.menu-item.active .menu-label,
.menu-item.active .menu-icon,
.menu-item.active .menu-icon-app,
.menu-item.active .menu-crest {
  opacity: 1;
}
.menu-item:focus {
  text-decoration: none !important;
  background-color: $contrast_semi_opaque;
  opacity: 1;
}
.menu-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  font-weight: 500;
  opacity: 0.96;
}

.message-input {
  font-size: 1em;
  border: none;
  font: inherit;
  line-height: 25px;
  padding-left: 0.8em;
  padding-right: 0.8em;
  margin-bottom: 40px;
  width: 100%;
  overflow: hidden;
}
.message-input-area,
.message-edit-area {
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  position: relative;
}
.message-input-button {
  background: $white;
  padding: 4px 0;
  border-radius: 6px;
  border: 2px solid $middle_soft;
  color: $middle_harder;
  font-size: 1.5em;
}
.message-buttons-container {
  /* width: 100%; */
  position: absolute;
  right: 0;
  bottom: 0;
}
.message-file-view {
  box-sizing: border-box;
  padding: 0.5rem;
  margin: 0.25rem 0;
  background: $middle_softest;
  border: 1px solid $middle_regular;
  outline: none;
  border-radius: 6px;
  transition: 0.3s;
  padding: 1em 0.5em;
}
.message-image {
  height: auto;
  width: auto;
  max-width: 280px;
  max-height: 280px;
  border: none;
}
.message-image-container {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: zoom-in;
  margin: 2px;
}
.message-uploaded-image {
  height: auto;
  width: auto;
  max-width: 140px;
  max-height: 140px;
  border: 2px solid $black;
  border-radius: 4px;
}
.message-uploaded-file {
  height: 100%;
  width: 130px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 14px;
  text-align: center;
  padding: 0 1em;
  border: 2px dashed $black;
  border-radius: 4px;
  background-color: $white;
}
.message-uploaded-file p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.middle {
  color: $middle_harder;
}

.mobile-center,
.web-center {
  text-align: center;
}
.mobile-justify,
.web-justify {
  justify-content: center;
}
.mobile-margin {
  margin: 0 auto;
}

.name,
.handle,
.handle2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.name {
  font-size: 1.2em;
  font-weight: 500;
}

div.natural-edit {
  font-size: 0.96em;
  box-sizing: border-box;
  padding: 0.5rem;
  margin: 0.25rem auto;
  background: $middle_softest;
  border: 1px solid $middle_regular;
  outline: none;
  border-radius: 6px;
}
div.natural-edit div {
  display: none;
}
div.natural-edit:focus-within {
  background: $extreme_soft;
  border: 1px solid $contrast_softer;
  box-shadow: 0 0 4px $contrast_softer;
}
div.natural-edit:focus-within div {
  display: unset;
}
div.natural-edit input,
div.natural-edit textarea {
  font-size: 1em;
  background: transparent;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
}
div.natural-edit input:focus,
div.natural-edit textarea:focus {
  background: transparent;
  border: none;
  box-shadow: none;
}

.page-card {
  background: $white;
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  padding: 0.5rem 0.2rem;
}

.page-notification {
  background-color: $success;
  color: $white;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 6px;
  padding: 0.5em;
  position: relative;
}
.page-notification > div {
  padding: 0.25em 0;
}
.page-notification * button {
  color: $white;
}

.page-section {
  padding-top: 1em;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.page-section.sub {
  padding-top: 0;
}

.password {
  display: flex;
  align-items: center;
  gap: 0.2em;
}
.password > input {
  width: 100%;
}
.password > div {
  color: $middle_harder;
  font-size: 1.4em;
  font-family: "Glyphs";
}
.password > div:hover {
  cursor: pointer;
}

.pop-notice {
  background: $notice_background;
  color: $white;
  padding: 0.5em 0.75em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 0;
}

.preview-screens {
  background-color: $contrast_extreme;
  padding: 2em 0;
  border-radius: 6px;
  color: $white;
}
.preview-screens > span {
  display: flex;
  justify-content: space-evenly;
}
.preview-screens img {
  border-radius: 12px;
  max-width: 32%;
}

.profile-image {
  object-fit: cover;
  margin-bottom: 0.5em;
  width: 80px;
  height: 80px;
  aspect-ratio: auto 80 / 80;
  border-radius: 50%;
}

.card-post {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $middle_regular;
  padding: 0.75em 1em;
}
.card-post > .card-username {
  display: none;
}

.post-title {
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-type {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}
.post-type > .glyphs {
  font-size: 1.4em;
}

.preview-card {
  display: flex;
  max-width: 60rem;
  background-color: $middle_softest;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 1px 2px $middle_regular;
  margin-bottom: 0.5em;
}
.preview-img {
  display: flex;
  background-color: $middle_hard;
  height: 84px;
  max-width: 84px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
}
.preview-icon {
  font-family: "Glyphs";
  flex: 0 0 84px;
  background-color: $middle_hard;
  height: 84px;
  text-align: center;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.preview-txt {
  display: flex;
  flex-direction: column;
  height: 84px;
  padding: 0 0.75em;
  justify-content: center;
  width: 100%;
}
.preview-card-title {
  width: 100%;
  font-family: serif;
  font-size: 1.2rem;
  font-weight: bold;
}

.price-box {
  width: 100%;
  text-align: center;
}
.price-box .currency {
  color: $middle_harder;
  font-size: 1.1em !important ;
  font-weight: 500 !important;
}
.price-box .headline {
  font-size: 1em !important;
}
.price-box .note {
  font-size: 0.8em !important;
}
.price-box .amount {
  font-weight: 700 !important;
  font-size: 1.6em !important;
}

.pricing {
  display: flex;
  margin: 0 auto;
  max-width: 900px;
}
.pricing > button {
  flex: 1;
  background: $white;
  border-color: $contrast_color;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  padding: 0.75em 0;
  font-size: 0.9em !important;
}
.pricing > button.on {
  color: $white;
  font-weight: 600 !important;
  background: $contrast_color;
}

.pricing-plan {
  text-align: center;
}
.pricing-plan > div {
  margin-top: 1em;
  flex: 1;
}
.pricing-plan > img {
  width: 100%;
  max-width: 580px;
  height: auto;
}
.pricing-plan h3 {
  margin: 1em 0 0;
  font-weight: 500 !important;
}
.pricing-plan ul {
  list-style: none;
  display: inline-block;
  margin: 0 -1em;
}
.pricing-plan li {
  margin: 0.5em 0;
}
.pricing-plan li::before {
  font-family: "Glyphs";
  color: $success;
  content: "*";
  margin: 0 0.5em;
}
.pricing-plan p {
  margin: 0.5em 0;
}

.public {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.public.bar {
  display: flex;
  align-items: center;
  padding: 0 6px;
  color: $white;
}

.public.section {
  padding: 1em 0.25em;
}
div.public.section * {
  font-weight: 300;
}

img.public-benefits {
  text-align: center;
  width: 100%;
  max-width: 400px;
  height: auto;
}

.public-nav {
  align-self: flex-end;
  font-size: 0.7em;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
}
.public-nav.on {
  border-bottom: 2px solid $contrast_extreme;
}

.public-footer {
  display: block;
  background: $main_background;
  width: 100%;
  padding-bottom: 2em;
  font-size: 0.7em;
  text-align: center;
}

.public-getyongo {
  text-align: center;
}
.public-getyongo img {
  margin: 0.5em 0;
  width: 100%;
  max-width: 400px;
}

.public-center {
  text-align: center;
  justify-content: center;
}

.quote-text {
  font-style: italic;
  color: $middle_hardest;
}

.red-dot {
  color: white;
  background: red;
  margin: 0.5em 1em;
  width: 10px;
  height: 10px;
  line-height: 10px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 600;
  overflow: hidden;
  text-align: center;
}

.shadow-text {
  color: $white;
  text-shadow: 1px 1px $black;
}

.sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: $side_font_color;
  overflow: hidden;
  height: auto;
  transition: width 250ms;
  top: 0px;
  z-index: 3;
  width: 0px;
}
.sidebar-background {
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: -1;
  transition: width 250ms;
  width: 0px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: $contrast_extreme;
}
.sidebar-container {
  flex-basis: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  transition: 250ms;
  // overflow: hidden;
}
.sidebar-logo {
  display: flex;
  flex-direction: row;
  padding: 1rem 0.2rem 0;
  font-size: 1.2rem;
  align-items: center;
}
.sidebar-menu-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $opaque_heavy;
}
.sidebar-overlay.visible {
  display: unset;
}
.signup {
  animation-duration: 300ms;
  grid-area: 1 / 1 / 2 / 2;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subheading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  padding: 0.25em;
  margin: 0;
}

.subtab > div {
  height: 2px;
  width: 75%;
  margin: 0 auto;
  background: black;
}
.subtab > span {
  display: inline-block;
  font-weight: 600;
  padding-bottom: 4px;
}
.subtab.off > div {
  opacity: 0;
}
.subtab.off > span {
  color: $middle_harder;
  font-weight: 500;
  cursor: pointer;
}

.success {
  color: $success;
}

.tab-box {
  margin-top: -1px;
  background-color: $middle_softest;
  border: 1px solid $middle_regular;
}

.tab-on,
.tab-off {
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.5em 1em;
  align-self: center;
  text-align: center;
  border-radius: 6px 6px 0 0;
}
.tab-off {
  border-top: 1px solid transparent;
  border-bottom: 1px solid $middle_regular;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  background-color: $middle_soft;
  color: $middle_harder;
}
.tab-on {
  border-top: 1px solid $middle_regular;
  border-bottom: 1px solid $middle_softest;
  border-left: 1px solid $middle_regular;
  border-right: 1px solid $middle_regular;
  background-color: $middle_softest;
  font-weight: 500;
}

.tag {
  white-space: nowrap;
}
.tag-o {
  width: 1em;
}
.tag-x:hover {
  cursor: pointer;
  color: $alert;
}
.tag-x:focus {
  text-decoration: underline;
}
.tag_width {
  max-width: 150px;
}
.tag-search-results {
  position: absolute;
  top: 0;
  width: 100%;
  background: $extreme_soft;
  border-top: none;
  border-bottom: 1px solid $middle_regular;
  border-left: 1px solid $middle_soft;
  border-right: 1px solid $middle_soft;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0;
  transition: all 150ms ease-in-out;
}
.tag-search-wrap {
  height: 2.5em;
  line-height: 1em;
  padding: 0.25em 0.5em;
  margin: 0.25em 0;
  overflow: hidden;
  cursor: pointer;
}
.tag-search-wrap:focus {
  background: $middle_hard;
  text-decoration: none;
}

.tag-wrap {
  display: flex;
  // flex-direction: column;
  flex-wrap: nowrap;
  padding: 0.75em;
  margin: 0.25em 0.5rem;
  background: $extreme_soft;
  border: 1px solid $middle_soft;
  font-size: 0.9em;
}

.text-label {
  margin: 0 0.25em;
  font-weight: 500;
}

.thread {
  background-color: $main_background;
}

.thumb-block {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  box-sizing: content-box;
  border-radius: 6px;
}

.thumb-block.border {
  border: 1px solid $middle_soft;
}
.thumb-block.small {
  width: 52px;
  height: 52px;
}
.thumb-block.tiny {
  width: 28px;
  height: 28px;
}
.thumb-container {
  padding: 10px;
  border-radius: 6px;
}
.thumb-container img {
  border: 1px solid $middle_soft;
}
.thumb-container:hover {
  background-color: $contrast_softest;
}
.thumb-glyph {
  position: relative;
  background-color: $middle_hard;
  font-family: "Glyphs";
  text-align: center;
  font-size: 3em;
  color: $white;
  line-height: 80px;
  flex-shrink: 0;
  width: 90px;
  height: 90px;
  box-sizing: content-box;
  border-radius: 6px;
}
.thumb-glyph.tiny {
  font-size: 1em;
  width: 32px;
  height: 32px;
  line-height: 29px;
}
.thumb-group {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: 200ms;
  box-sizing: content-box;
  width: 190px;
}
.thumb-group.small {
  padding: 2px;
  width: 120px;
}
.thumb-group.vid {
  padding: 2px;
  width: 150px;
  height: 200px;
}
.thumb-group-content {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $extreme_soft;
  box-shadow: 1px 1px 2px #ddd;
}

.thumb-hover:hover {
  cursor: pointer;
  background-color: $contrast_softest;
}

.thumb-group-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
  max-width: 1600px;
  box-sizing: content-box;
}

.thumb-paused {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
}

.thumb-vid {
  width: 150px;
  height: 200px;
  overflow: hidden;
}
.thumb-vid.unavailable {
  background: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.thumb-vid.unavailable > div {
  padding: 6px;
}

.topbar {
  height: 5em;
  color: $white;
  box-shadow: 0 0 4px 2px $shading_faint;
  display: flex;
  align-items: center;
  background: $contrast_extreme;
}
.topbar > button {
  font-family: "Glyphs";
  cursor: pointer;
  font-size: 2em;
  height: 2em;
  color: $white;
}

.trans500 {
  overflow: hidden;
  transition: 500ms;
}

.video-button {
  position: absolute;
  font-size: 84px;
  top: 50%;
  left: 0;
  font-family: "Glyphs";
  color: $middle_regular;
  opacity: 0.8;
  width: 100%;
  text-align: center;
  margin-top: -36px;
}

.video-track {
  position: absolute;
  z-index: 10;
  height: 3px;
  background-color: rgba(100, 100, 100, 100);
}
.video-track > div {
  height: 3px;
  background-color: $alt_light;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.warning-banner {
  background-color: $invalid_softest;
  border-top: 1px solid $middle_soft;
  border-bottom: 1px solid $middle_soft;
  overflow: hidden;
  transition: 500ms;
}

.web-search {
  display: flex;
  align-items: center;
  margin: 0 0.5em;
}
.web-search > input {
  border-radius: 1.1em;
}
.web-search > button {
  font-family: "Glyphs";
  margin: 0 0.5rem;
  color: $middle_hardest;
  font-size: 1.4em;
}
.web-search > div {
  z-index: 10;
  color: $middle_harder;
  font-size: 1.1em;
}
