/***  MEDIA FORMATTING WIDTH ***/

/* over 380px wide */
@media screen and (min-width: 380px) {
  .crest-edit-container {
    padding: 10px;
  }

  .login {
    padding: 3em 1.5em;
  }
  .login-heading {
    font-size: 2rem;
  }

  .modal-content {
    padding: 0.5rem;
  }

  .preview-img {
    max-width: 117px;
  }

  .public-nav {
    font-size: 0.9em;
  }

  .tag_width {
    max-width: 250px;
  }
}

/* over 500px wide */
@media screen and (min-width: 500px) {
  /* body,
  html {
    font-size: 15px;
  } */

  .auto-margin-narrow {
    margin: unset;
  }

  .card-block,
  .card-block-success {
    flex-direction: row;
  }
  .card-block-summary {
    display: flex;
    /* flex-direction: row; */
  }

  div.img-view {
    font-size: 3em;
    padding: 0.25rem;
  }

  .block-info {
    padding: 0 1em;
    width: calc(100% - 140px);
  }

  .login-heading {
    font-size: 2.2rem;
  }

  .modal-content {
    padding: 1rem;
    margin: 2rem auto;
    width: calc(100% - 2rem);
    max-width: 700px;
    border-radius: 6px;
    max-height: calc(100vh - 5rem);
  }
  .modal-dynamic {
    min-height: unset;
    height: unset;
  }

  .page-card {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    padding: 0.75rem;
  }

  .page-notification {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1em;
  }

  .page-section {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .preview-img {
    max-width: 149px;
  }

  .pricing > button {
    font-size: 1em !important;
  }

  .public-nav {
    font-size: 1em;
  }

  .card-post > .card-username {
    display: unset;
  }

  .tag_width {
    max-width: 300px;
  }

  .card-long {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 1em;
  }
}

/* over 680px wide */
@media screen and (min-width: 680px) {
  .preview-img {
    max-width: 15rem;
  }

  div.img-view {
    font-size: 4em;
    padding: 0.5rem;
  }
}

/* over 900px wide */
@media screen and (min-width: 900px) {
  .live-stage {
    display: flex;
    position: relative;
  }
  .live-panel {
    margin-top: 0;
    position: unset;
    height: unset;
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .live-panel-box {
    width: 30%;
  }
  .live-control.message {
    width: 30%;
  }
  .live-video {
    flex-basis: 70%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .mobile-center {
    text-align: unset;
  }
  .mobile-justify {
    justify-content: flex-start;
  }
  .mobile-margin {
    margin: 0px;
  }

  .modal-content {
    padding: 1rem 2rem;
    margin: 3rem auto;
    width: 700px;
  }

  div.natural-edit {
    margin: 0.25rem 0;
  }

  .page-card {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .page-notification {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .page-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .price-box {
    width: 400px;
  }
  .pricing > button {
    font-size: 1.1em !important;
  }
  .pricing-plan {
    display: flex;
    gap: 1em;
  }
  .pricing-plan > div {
    text-align: initial;
    margin-top: -3.8em;
  }
  .pricing-plan > img {
    width: 400px;
    height: 200px;
    max-width: unset;
  }

  .public.section {
    padding: 1em 0.5em;
  }
  .public-getyongo {
    display: flex;
    gap: 1em;
  }
  .public-getyongo img {
    width: 300px;
    height: 300px;
  }
  .public-getyongo > div {
    text-align: center;
  }

  img.public-benefits {
    max-width: 400px;
    width: 400px;
    height: 400px;
    margin: -30px auto 0 auto;
    display: block;
  }

  .sidebar-container {
    flex-basis: 220px;
  }

  .sidebar {
    width: 220px;
  }
  .sidebar-background {
    width: 220px;
  }

  .sidebar-overlay.visible {
    display: none !important;
  }

  .thumb-group-container {
    margin: 0 -8px;
  }

  .topbar {
    display: none;
  }

  .web-search {
    margin: 0;
  }
  .web-search > input {
    /* width: "100%"; */
  }
}

/***  MEDIA FORMATTING HEIGHT ***/
/* under 720px high */
@media screen and (max-height: 720px) {
  .login {
    padding-top: 0.5em;
  }
}
