@import "theme";

#root {
  height: 100%;
}

html,
body {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  background-color: $main_background;
  color: $main_font_color;
  overflow-y: auto;
  overflow-x: hidden; /*fixes negative horizontal margin on thumb container */
}

div {
  box-sizing: border-box;
  outline-width: 1px;
}

button {
  font-size: 0.96em;
  cursor: pointer;
  background-color: transparent;
  border-width: 0;
  outline-width: 1px;
  outline-color: $main_font_color;
  padding: 0;
  margin: 0;
}

label {
  font-size: 1em;
}

input,
select,
textarea {
  font-size: 0.96em;
  box-sizing: border-box;
  padding: 0.5rem;
  margin: 0.25rem 0;
  background: $middle_softest;
  border: 1px solid $middle_regular;
  outline: none;
  border-radius: 6px;
}

input:focus,
select:focus,
textarea:focus {
  background: $extreme_soft;
  border: 1px solid $contrast_softer;
  box-shadow: 0 0 4px $contrast_softer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: $contrast_color;
  opacity: 0.9;
  cursor: pointer;
  margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic*/
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $extreme_hard;
  border-radius: 6px;
}

input[type="range"] {
  background: transparent;
  -webkit-appearance: none;
  border: none;
  margin: 0;
}

select.large {
  font-size: 1.1em;
}

table,
tr,
th,
td {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: none;
  white-space: pre-wrap;
}

ul {
  padding-inline-start: 1em;
}

li,
ul {
  margin: 0.75em 0;
}

video {
  width: 100%;
}

/*** ACCESSIBILITY ***/
h1,
.heading2,
.heading3 {
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  padding: 0;
  outline-width: 1px;
  outline-color: $main_font_color;
}

.focus-modest {
  outline-width: 0;
}

input[type="range"]:focus {
  src: format();
  outline-style: solid;
  outline-width: 1px;
  outline-color: $main_font_color;
}

.sr-only {
  position: fixed;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only.visible {
  width: unset;
  height: unset;
  padding: 0.75em 1em;
  margin: 0.75em;
  overflow: unset;
  clip: unset;
  z-index: 10;
  background: $extreme_soft;
}

/*** LOADING DIV ***/
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $opaque_regular;
  width: 100%;
  height: 100vh;
  cursor: wait;
  display: none;
  z-index: 100;
}
.loader {
  width: 92px;
  height: 92px;
  position: fixed;
}

/***  MODALS  ***/

.modal,
.modal-image {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.modal {
  background: $shading_medium;
}

.modal-content {
  position: relative;
  overflow: hidden;
  background: $middle_softest;
  color: $middle_hardest;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5rem 0.25em;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  -moz-animation-name: animatetop;
  -moz-animation-duration: 0.5s;
  -ms-animation-name: animatetop;
  -ms-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s;
}

.modal-dynamic {
  min-height: 100%;
  height: 100px;
  overflow-y: auto;
}

.modal-heading {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 3rem;
  height: 3rem;
  flex-shrink: 0;
}

.modal-image {
  background: $shading_heavy;
  color: $extreme_soft;
}

/***  ANIMATION  ***/

.left {
  animation-name: slide-left;
}
.right {
  animation-name: slide-right;
  transform: translateX(100%);
}

/* fade */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeSmooth {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInSmooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

/* fly from top */
@-ms-keyframes animatetop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@-moz-keyframes animatetop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes animatetop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* slide */
@keyframes slidein {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideback {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* spin */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* dot dot dot blinking for waiting */
@-ms-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-moz-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/* flashing text */
@keyframes blink {
  50% {
    opacity: 0.2;
  }
}
